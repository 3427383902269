<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Berdasar Nama Diagnosa"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive="sm"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }"
        />
      </template>
      <template #cell(actions)="data">
        <b-button
          size="sm"
          variant="primary"
          class="mr-1"
          @click="savePatientXDiagnose(data.item.id)"
        >
          Pilih
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    patient_id: Number,
    arrSelectedDiagnose: Array,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      selectedItemId: [],
      // form
      form: {
        patient_id: "",
        diagnose_id: "",
      },
      // user access
      btn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;

      this.pagination();
    },

    async savePatientXDiagnose(diagnose_id) {
      this.form.patient_id = this.patient_id;
      this.form.diagnose_id = diagnose_id;

      // Make Request
      let response = await module.submit(this.form, "patient-x-diagnoses");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        this.selectedItemId = [];
        this.pagination();
        this.$emit("updatePatientXDiagnoseTable", response);
      }
    },

    async getPatientXDiagnoseId() {
      this.selectedItemId = [];
      let route = "patient-x-diagnoses";
      let response = await module.paginate(
        route,
        `?patient_id=${this.patient_id}`
      );

      response.data.forEach((el) => {
        this.selectedItemId.push(el.diagnose_id);
      });
    },

    async pagination() {
      await this.getPatientXDiagnoseId();

      let filterParams = `&selectedItem=${this.selectedItemId}`;
      let response = await module.paginate(
        "diagnoses",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      debounce(() => {
        this.pagination();
      }, 500);
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("diagnoses/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveButton();
    this.$root.$on("updateDiganoseItemTableContent", () => {
      this.pagination();
    });
  },
};
</script>

<style lang="scss" scoped></style>